import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import Cookie from '../../components/CookieNotice.js';
import Header from '../../components/Header.js';
import Social from '../../components/SocialIcons.js';
import './Legal.css';



function Privacy() {
      const { t } = useTranslation('legal');


      return (
        <div className="Privacy">
        <Cookie />
          <Header />
          <div className="Legal-content container">
              <div className="Legal-text">
                <h1>{t('privacy.title')}</h1>
                <h2>{t('privacy.subtitle1')}</h2>
                <p>{t('intro.desc1')}</p>
                <p>{t('intro.desc2')}</p>
                <p>{t('intro.desc3')}</p>
                <h2>{t('privacy.subtitle2')}</h2>
                <p>Sophia Fuhrmann<br/>Rudolstädter Str. 27<br/>07549 Gera, {t('germany')}</p>
                <p>{t('person.email')} <a href="mailto:me@the-sophia.dev">me@the-sophia.dev</a></p>
                <p>{t('person.legal')} <Link to="/legal">the-sophia.dev/legal</Link></p>
                <h2>{t('privacy.subtitle3')}</h2>
                <p>{t('oview.desc1')}</p>
                <h3>{t('types_title')}</h3>
                <ul><li>{t('data.types_item1')}</li><li>{t('data.types_item2')}</li><li>{t('data.types_item3')}</li></ul>
                <h3>{t('data.persons_title')}</h3>
                <ul><li>{t('data.persons_item1')}</li><li>{t('data.persons_item2')}</li></ul>
                <h3>{t('aim_title')}</h3>
                <ul><li>{t('data.aim_item1')}</li><li>{t('data.aim_item2')}</li><li>{t('data.aim_item3')}</li><li>{t('data.aim_item4')}</li></ul>
                <h3>{t('legal_title')}</h3>
                <p><li>{t('data.legal_desc')}</li></p>
                <ul>
                  <li><strong>{t('data.legal_item1_b')}</strong> {t('data.legal_item1')}</li>
                  <li><strong>{t('data.legal_item2_b')}</strong> {t('data.legal_item2')}</li>
                  <li><strong>{t('data.legal_item3_b')}</strong> {t('data.legal_item3')}</li>
                  <li><strong>{t('data.legal_item4_b')}</strong> {t('data.legal_item4')}</li>
                </ul>
                <p>{t('oview.desc2')}</p>
                <h2>{t('privacy.subtitle4')}</h2>
                <p>{t('security.desc1')}</p>
                <p>{t('security.desc2')}</p>
                <p>{t('security.desc3')}</p>
                <h2>{t('privacy.subtitle5')}</h2>
                <p>{t('third.desc1')}</p>
                <p>{t('third.desc2')} <a href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de" target="_blank">https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de</a>).</p>
                <h2>{t('privacy.subtitle6')}</h2>
                <p>{t('delete.desc1')}</p>
                <p>{t('delete.desc2')}</p>
                <p>{t('delete.desc3')}</p>
                <h2>{t('privacy.subtitle7')}</h2>
                <p>{t('cookies.desc1')}</p>
                <p><strong>{t('cookies.desc2_b')}</strong>{t('cookies.desc2')}</p>
                <p><strong>{t('cookies.desc3_b')}</strong>{t('cookies.desc3')}</p>
                <p><strong>{t('cookies.desc4_b')}</strong>{t('cookies.desc4')}</p>
                <ul>
                  <li><strong>{t('cookies.item1_b')}</strong>{t('cookies.item1')}</li>
                  <li><strong>{t('cookies.item2_b')}</strong>{t('cookies.item2')}</li>
                </ul>
                <p><strong>{t('cookies.desc5_b')}</strong>{t('cookies.desc5')}</p>
                <p><strong>{t('further')}</strong></p>
                <ul>
                  <li><strong>{t('cookies.desc6_b')}</strong>{t('cookies.desc6')}</li>
                  <li><strong>CCM19 - Cookie Consent Manager: </strong>{t('cookies.item3')} <strong>{t('provider')}</strong> CCM19; Papoo Software & Media GmbH, Auguststr. 4, D-53229 Bonn, {t('germany')}; <strong>{t('website')}</strong> <a href="https://www.ccm19.de/" target="_blank">https://www.ccm19.de/</a>; <strong>{t('privacy_title')}</strong> <a href="https://www.ccm19.de/datenschutzerklaerung.html" target="_blank">https://www.ccm19.de/datenschutzerklaerung.html</a>; <strong>{t('cookies.item4_b')}</strong> {t('cookies.item4')}</li>
                </ul>
                <h2>{t('privacy.subtitle8')}</h2>
                <p>{t('host.desc1')}</p>
                <p>{t('host.desc2')}</p>
                <ul>
                  <li><strong>{t('types_title')}</strong> {t('host.types')}</li>
                  <li><strong>{t('persons')}</strong> {t('host.persons')}</li>
                  <li><strong>{t('aim_title')}</strong> {t('host.aim')}</li>
                  <li><strong>{t('legal_title')}</strong> {t('host.legal')}</li>
                </ul>
                <p><strong>{t('further')}</strong></p>
                <ul>
                  <li><strong>{t('host.item1_b')}</strong>{t('host.item1')}</li>
                  <li><strong>{t('host.item2_b')}</strong>{t('host.item1')} <strong>{t('host.item3_b')}</strong> {t('host.item3')}</li>
                  <li><strong>netcup: </strong>{t('host.item4')} <strong>{t('provider')}</strong> netcup GmbH, Daimlerstraße 25, D-76185 Karlsruhe, {t('germany')}; <strong>{t('website')}</strong> <a href="https://www.netcup.de/" target="_blank">https://www.netcup.de/</a>; <strong>{t('privacy_title')}</strong> <a href="https://www.netcup.de/kontakt/datenschutzerklaerung.php" target="_blank">https://www.netcup.de/kontakt/datenschutzerklaerung.php</a>; <strong>{t('contract')}</strong> <a href="https://www.netcup-wiki.de/wiki/Zusatzvereinbarung_zur_Auftragsverarbeitung" target="_blank">https://www.netcup-wiki.de/wiki/Zusatzvereinbarung_zur_Auftragsverarbeitung</a>.</li>
                </ul>
                <h2>{t('privacy.subtitle9')}</h2>
                <p>{t('contact.desc1')}</p>
                <p>{t('contact.desc1')}</p>
                <ul>
                  <li><strong>{t('types_title')}</strong> {t('contact.types')}</li>
                  <li><strong>{t('persons')}</strong> {t('contact.persons')}</li>
                  <li><strong>{t('aim_title')}</strong> {t('contact.aim')}</li>
                  <li><strong>{t('legal_title')}</strong> {t('contact.legal')}</li>
                </ul>
                <p><strong>{t('further')}</strong></p>
                <ul>
                  <li><strong>{t('contact.item_b')} </strong>{t('contact.item')}</li>
                </ul>
                <h2>{t('privacy.subtitle11')}</h2>
                <p>{t('social.desc1')}</p>
                <p>{t('social.desc2')}</p>
                <p>{t('social.desc3')}</p>
                <p>{t('social.desc4')}</p>
                <p>{t('social.desc5')}</p>
                <ul>
                  <li><strong>{t('types_title')}</strong> {t('social.types')}</li>
                  <li><strong>{t('persons')}</strong> {t('social.persons')}</li>
                  <li><strong>{t('aim_title')}</strong> {t('social.aim')}</li>
                  <li><strong>{t('legal_title')}</strong> {t('social.legal')}</li>
                </ul>
                <p><strong>{t('further')}</strong></p>
                <ul>
                  <li><strong>LinkedIn: </strong>{t('social.item1')} <strong>{t('provider')}</strong> {t('social.provider1')} <strong>{t('website')}</strong> <a href="https://www.linkedin.com" target="_blank">https://www.linkedin.com</a>; <strong>{t('privacy_title')}</strong> <a href="https://www.linkedin.com/legal/privacy-policy" target="_blank">https://www.linkedin.com/legal/privacy-policy</a>; <strong>{t('contract')}</strong> <a href="https://legal.linkedin.com/dpa" target="_blank">https://legal.linkedin.com/dpa</a>; <strong>{t('social.clauses')}</strong> <a href="https://legal.linkedin.com/dpa" target="_blank">https://legal.linkedin.com/dpa</a>; <strong>{t('social.optout')}</strong> <a href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out" target="_blank">https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out</a>.</li>
                  <li><strong>Xing: </strong>{t('social.item1')} <strong>{t('provider')}</strong> XING AG, Dammtorstraße 29-32, 20354 Hamburg, {t('germany')}; <strong>{t('website')}</strong> <a href="https://www.xing.de" target="_blank">https://www.xing.de</a>; <strong>{t('privacy_title')}</strong> <a href="https://privacy.xing.com/de/datenschutzerklaerung" target="_blank">https://privacy.xing.com/de/datenschutzerklaerung</a>.</li>
                </ul>
                <h2>{t('privacy.subtitle12')}</h2>
                <p>{t('update.desc1')}</p>
                <p>{t('update.desc2')}</p>
                <p><a href="https://datenschutz-generator.de/" title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken." target="_blank" rel="noopener noreferrer nofollow"><img src="https://datenschutz-generator.de/wp-content/plugins/ts-dsg/images/dsg-seal/dsg-seal-pp-de.png" alt="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken." width="100" height="100"/></a></p>
          </div>
        </div>
        <Social />
      </div>
      );
    }


    export default Privacy;
