import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import Cookie from '../components/CookieNotice.js';
import Header from '../components/Header.js';
import Social from '../components/SocialIcons.js';
import Foto from '../img/Bewerbungsfoto2.jpg';
import './About.css';



function About() {

      const { t } = useTranslation('about');


      return (
        <div className="About">
        <Cookie />
          <Header />
          <div className="About-content container">
              <div className="About-text">
                <h1>{t('title')}</h1>
                <p>{t('desc1')}</p>
                <p>{t('desc2')}</p>
                <p>{t('desc3')}</p>
                <p>{t('desc4')}</p>
                <p className="About-contact">{t('link1')} <Link to="/contact">{t('link2')}</Link>!</p>
              </div>
              <div className="About-picture">
                <img src={Foto} className="About-img" alt="Sophia Fuhrmann" />
              </div>
          </div>
          <Social />
        </div>
      );
    }


    export default About;
