import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import emailjs from '@emailjs/browser';
import Cookie from '../components/CookieNotice.js';
import Header from '../components/Header.js';
import Social from '../components/SocialIcons.js';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import './Contact.css';



function Contact() {
    const { t } = useTranslation('common', 'contact');

    const form = useRef();

    const sendMail = (e) => {
      e.preventDefault();

      emailjs.sendForm('service_afhrzx4', 'template_f0jgf6q', form.current, 'MkAXXofqHBSQdu5_q')
        .then((result) => {
          console.log(result.text);
          alert(<p>{t('contact:success')}</p>);
        }, (error) => {
          console.log(error.text);
          alert(<p>{t('contact:error')}</p>);
        });
    };

      return (
        <div className="Contact">
        <Cookie />
          <Header />
          <div className="Contact-content container">
            <h1>{t('contact:title')}</h1>
            <p>{t('contact:desc')}</p>
            <div className="Contact-form container">
              <Form ref={form} onSubmit={sendMail}>
                <Row>
                  <Col>
                    <Form.Control type="text" placeholder="Name" name="name" required/>
                  </Col>
                  <Col>
                    <Form.Control type="email" placeholder={t('contact:email')} name="email" required/>
                  </Col>
                </Row>
                <Form.Control type="text" placeholder={t('contact:subject')} name="subject" required/>
                <Form.Control id="Contact-message" type="textarea" placeholder={t('contact:message')} name="message" required/>
                <Form.Check type="switch" id="custom-switch" label={t('contact:privacy')} href="#" value="privacy-check" required />
                <div className="Contact-button">
                  <Button id="Contact-submit" type="submit" value="Send"> {t('contact:submit')} </Button>
                </div>
              </Form>
            </div>
            <div className="Contact-social">
              <h2>Let's get social</h2>
              <div className="Contact-social-btns">
                <Button size="lg" className="Contact-social-btn" href="https://github.com/TheSophiaDev" target="_blank"><i class="fa-brands fa-github"></i>GitHub</Button>
                <Button size="lg" className="Contact-social-btn" href="https://www.linkedin.com/in/sophia-fuhrmann-645980217/" target="_blank"><i class="fa-brands fa-linkedin-in"></i>LinkedIn</Button>
                <Button size="lg" className="Contact-social-btn" href="https://www.xing.com/profile/Sophia_Fuhrmann6/cv" target="_blank"><i class="fa-brands fa-xing"></i>Xing</Button>
              </div>
            </div>
          </div>
          <Social />
        </div>
      );
    }


    export default Contact;
