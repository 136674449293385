import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";

function Cookie () {
const { t, i18n } = useTranslation();

  return (
    <CookieConsent
      location="bottom"
      buttonText={t("cookie.button")}
      cookieName="CookieConsentNotice"
      style={{ background: "#212529", borderTop: "2px solid #00AA95" }}
      buttonStyle={{ background: "#212529", fontWeight: "600", color: "#00AA95", fontSize: "15px", border: "1px solid #00AA95"}}
      expires={150}
    >
      {t('cookie.part1')}{" "}
      {t('cookie.part2')} <Link to="/privacy">{t("cookie.link")}</Link>.
    </CookieConsent>
  )};

export default Cookie;
