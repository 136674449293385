import { useTranslation } from 'react-i18next';

function LngButtons() {
    const { t, i18n } = useTranslation('common', 'home');

    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
    };

    return(
      <span className="nav-lng">
        <span className="nav-link nav-sep">|</span>
        <button type="button" className="lng-button nav-link" onClick={() => changeLanguage('de')}>DE</button>
        <span className="lang-sep">|</span>
        <button type="button" className="lng-button nav-link" onClick={() => changeLanguage('en')}>EN</button>
      </span>
    )};

export default LngButtons;
