import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import Cookie from './components/CookieNotice.js';
import LngButtons from './components/ChangeLng.js';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Carousel from 'react-bootstrap/Carousel';
import Phoenix from './img/Phoenix.svg';
import Shirt from './img/Shirt.svg';
import BasicStream from './img/BasicStream.svg';
import Yoga from './img/Yoga.svg';
import Logo from './img/Logo_devLogick.png';
import './Home.css';
import './components/Header.css';


function Home() {
    const { t } = useTranslation('common', 'home');

    return (
      <div className="Home">
        <Cookie />
        <header className="Home-header">
         <Navbar bg="dark" variant="dark" collapseOnSelect expand="lg">
          <Container>
            <Link to="/" class="navbar-brand">Sophia Fuhrmann</Link>
            <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
            <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
              <Nav>
                <Link to="/skills" role="button" class="nav-link" tabindex="0">{t('nav.part1')}</Link>
                <Link to="/about" role="button" class="nav-link" tabindex="0">{t('nav.part2')} </Link>
                <Link to="/contact" role="button" class="nav-link" tabindex="0">{t('nav.part3')}</Link>
                <NavDropdown title={t('nav.legal')} id="basic-nav-dropdown">
                  <Link to="/legal" role="button" class="dropdown-item" data-rr-ui-dropdown-item tabindex="0">{t('nav.legal1')}</Link>
                  <Link to="/privacy" role="button" class="dropdown-item" data-rr-ui-dropdown-item tabindex="0">{t('nav.legal2')}</Link>
                </NavDropdown>
                <LngButtons />
              </Nav>
            </Navbar.Collapse>
          </Container>
         </Navbar>
        </header>
        <div className="Home-start">
          <img src={Phoenix} className="Home-svg-start" alt="Phoenix" />
          <div className="Home-me-title">
            <h1>Sophia Fuhrmann</h1>
          </div>
        </div>
       <div className="Home-content">
           <Carousel className="Home-carousel" id="CarouselSlider">
             <Carousel.Item className="Home-carousel-item shirt">
               <img src={Shirt} className="Home-svg" alt="sELfmAdebyEla" />
               <Carousel.Caption className="Home-carousel-text">
                 <h3><a href="https://devlogick.com/portfolio-item/selfmadebyela/" target="_blank">sELfmAdebyEla</a></h3>
                 <div className="Home-skill-icons">
                   <span title="WordPress"><i class="fa-brands fa-wordpress-simple"></i></span>
                   <span title="HTML5"><i class="fa-brands fa-html5"></i></span>
                   <span title="CSS3"><i class="fa-brands fa-css3-alt"></i></span>
                 </div>
               </Carousel.Caption>
             </Carousel.Item>
             <Carousel.Item className="Home-carousel-item basic-stream">
               <img src={BasicStream} className="Home-svg" alt="BasicStream" />
               <Carousel.Caption className="Home-carousel-text">
                 <h3><a href="https://www.basic-stream.de" target="_blank">Basic Stream</a></h3>
                 <div className="Home-skill-icons">
                   <span title="ModX"><i class="fa-brands fa-modx"></i></span>
                   <span title="Webdesign"><i class="fa-solid fa-paintbrush"></i></span>
                   <span title="PHP"><i class="fa-brands fa-php"></i></span>
                   <span title="JavaScript"><i class="fa-brands fa-js-square"></i></span>
                   <span title="MySQL"><i class="fa-solid fa-database"></i></span>
                   <span title="HTML5"><i class="fa-brands fa-html5"></i></span>
                   <span title="CSS3"><i class="fa-brands fa-css3-alt"></i></span>
                 </div>
               </Carousel.Caption>
             </Carousel.Item>
             <Carousel.Item className="Home-carousel-item yoga">
               <img src={Yoga} className="Home-svg" alt="Nareia Verein" />
               <Carousel.Caption className="Home-carousel-text">
                 <h3><a href="https://www.nareia.at" target="_blank">{t('home:nareia')}</a></h3>
                 <div className="Home-skill-icons">
                   <span title="WordPress"><i class="fa-brands fa-wordpress-simple"></i></span>
                   <span title="Webdesign"><i class="fa-solid fa-paintbrush"></i></span>
                   <span title="HTML5"><i class="fa-brands fa-html5"></i></span>
                   <span title="CSS3"><i class="fa-brands fa-css3-alt"></i></span>
                 </div>
               </Carousel.Caption>
             </Carousel.Item>
             <Carousel.Item className="Home-carousel-item yoga">
               <img src={Logo} className="Home-svg" id="Logo-img" alt="devLogick" />
               <Carousel.Caption className="Home-carousel-text">
                 <h3><a href="https://www.devlogick.com" target="_blank">devLogick</a></h3>
                 <div className="Home-skill-icons">
                   <span title="WordPress"><i class="fa-brands fa-wordpress-simple"></i></span>
                   <span title="Webdesign"><i class="fa-solid fa-paintbrush"></i></span>
                   <span title="HTML5"><i class="fa-brands fa-html5"></i></span>
                   <span title="CSS3"><i class="fa-brands fa-css3-alt"></i></span>
                 </div>
               </Carousel.Caption>
             </Carousel.Item>
          </Carousel>
       </div>
      </div>
    );
}


export default Home;
