import React from 'react';
import { useTranslation } from 'react-i18next';
import Cookie from '../../components/CookieNotice.js';
import Header from '../../components/Header.js';
import Social from '../../components/SocialIcons.js';
import './Legal.css';



function Legal() {
      const { t } = useTranslation('legal');

      return (
        <div className="Legal">
        <Cookie />
          <Header />
          <div className="Legal-content container">
            <div className="Legal-text">
              <h1>{t('legal.title1')}</h1>
              <h2>{t('legal.title2')}</h2>
              <p>Sophia Fuhrmann</p>
              <h3>{t('legal.title3')}</h3>
              <p>Rudolstädter Str. 27 <br/>07549 Gera</p>
              <h3>{t('legal.title4')}</h3>
              <p>{t('legal.desc1')}<br/>{t('legal.desc2')}</p>
              <h2>{t('legal.title5')}</h2>
              <h3>{t('legal.title6')}</h3>
              <p>{t('legal.desc3')} <br/> {t('legal.desc4')}</p>
              <h3>{t('legal.title7')}</h3>
              <p>{t('legal.desc5')} </p>
              <h2>{t('legal.title8')}</h2>
              <p>{t('legal.desc6')} <a href="https://ec.europa.eu/consumers/odr">https://ec.europa.eu/consumers/odr</a>.<br/>
                 {t('legal.desc7')}</p>
            </div>
          </div>
          <Social />
        </div>
      );
    }


    export default Legal;
