import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';


i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)

  .init({
    lng: 'de',
    fallbackLng: 'de',
    debug: true,
    ns: ['common', 'home', 'about', 'skills', 'contact', 'legal'],
    defaultNS: 'common',
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    load: 'languageOnly',
    react: {
      wait: true,
    },
    interpolation: {
      espaceValue: false,
      keySeparator: '.',
    },
   });


export default i18n;
