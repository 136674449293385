import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, Link } from "react-router-dom";
import LngButtons from './ChangeLng.js';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import './Header.css';


function Header() {
  const { t } = useTranslation();


  return (
    <header className="Header">
     <Navbar bg="dark" variant="dark" collapseOnSelect expand="lg">
      <Container>
        <Link to="/" class="navbar-brand">Sophia Fuhrmann</Link>
        <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
          <Nav>
            <NavLink className="nav-link" activeClassName="active nav-link" to="/skills" role="button" tabindex="0">{t('nav.part1')}</NavLink>
            <NavLink className="nav-link" activeClassName="active nav-link" to="/about" role="button" tabindex="0">{t('nav.part2')} </NavLink>
            <NavLink className="nav-link" activeClassName="active nav-link" to="/contact" role="button" tabindex="0">{t('nav.part3')}</NavLink>
            <NavDropdown title={t('nav.legal')} id="basic-nav-dropdown">
              <NavLink className="dropdown-item" activeClassName="active dropdown-item" to="/legal" role="button" data-rr-ui-dropdown-item tabindex="0">{t('nav.legal1')}</NavLink>
              <NavLink className="dropdown-item" activeClassName="active dropdown-item" to="/privacy" role="button" data-rr-ui-dropdown-item tabindex="0">{t('nav.legal2')}</NavLink>
            </NavDropdown>
            <LngButtons />
          </Nav>
        </Navbar.Collapse>
      </Container>
     </Navbar>
    </header>
  )};

export default Header;
