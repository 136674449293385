import React, { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from 'react-bootstrap/Button';
import './Social.css';

function Social() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button onClick={handleShow} className="btn-toggle">
        <i class="fa-solid fa-chevron-left"></i>
      </Button>
      <Offcanvas show={show} onHide={handleClose} placement={'end'}>
        <Offcanvas.Body>
          <Offcanvas.Header closeButton></Offcanvas.Header>
          <div className="social-links">
            <a href="https://github.com/TheSophiaDev" target="_blank" className="nav-link social-icon"><i class="fa-brands fa-github"></i></a>
            <a href="https://www.linkedin.com/in/sophia-fuhrmann-645980217/" target="_blank" className="nav-link social-icon"><i class="fa-brands fa-linkedin-in"></i></a>
            <a href="https://www.xing.com/profile/Sophia_Fuhrmann6/cv" target="_blank" className="nav-link social-icon"><i class="fa-brands fa-xing"></i></a>
          </div>
        </Offcanvas.Body>
       </Offcanvas>
    </>
  )};

export default Social;
